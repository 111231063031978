import { get, noop, debounce } from 'lodash';
import { createStore, applyMiddleware, bindActionCreators } from 'redux';
// import { composeWithDevTools } from 'remote-redux-devtools';
import thunk from 'redux-thunk';
import { LIKE_POST } from '../../app/constants/interactions';
import createRequest from '@wix/communities-forum-client-commons/dist/src/services/create-request';
import fetchRecentPosts from '../actions/fetch-recent-posts';
import {
  incrementPostLikeCount,
  INCREMENT_POST_LIKE_COUNT_SUCCESS,
} from '../../common/actions/increment-post-like-count';
import reducers from '../reducers';
import { fetchAllCategories } from '../../common/actions/fetch-categories';
import { fetchBadges } from '../../app/actions/fetch-badges';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { showJoinCommunityPopup } from '../../common/actions/join-community';
import {
  isMaInstalled,
  setCommunitiesContext,
} from '../../common/store/communities-context/communities-context-actions';
import { initializeMaNavigation } from '../../common/store/ma-navigation/ma-navigation-actions';
import { parseInstance } from '@wix/communities-forum-client-commons/dist/src/services/parse-instance';
import { setBasicParams, setInstance } from '../../common/store/basic-params/basic-params-actions';
import { fetchExperiments } from '../../common/actions/fetch-experiments';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import {
  createSentryMonitor,
  decorateActionsWithLogger,
  ENVIRONMENT,
} from '../../common/services/monitoring';
import { fetchUser } from '../../common/actions/fetch-user';
import listenToSettingsChange, {
  applySettingsChange,
} from '../../app/services/listen-to-settings-change';
import { navigateWithinForum } from '../../common/actions/navigate-within-forum';
import { fetchTranslations } from '../../common/store/translations/translations-actions';
import { initializeLocation } from '../../common/store/location/location-actions';
import createInteractionsMiddleware from '../../common/interactions/create-interactions-middleware';
import { initialiseInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import { handleProvisioning } from '../../common/services/handle-provisioning';
import { initBiMiddleware } from '../../app/bi-events/init-middleware';
import { handleError } from '../../common/store/debug-state/handle-error';
import {
  LIST_NUMBER_OF_POSTS,
  WIDGET_CATEGORY_ID,
  WIDGET_POSTS_ORDER_BY,
} from '@wix/communities-forum-client-commons/dist/src/constants/wix-params-rpw';
import { validateLanguage } from '../../common/services/validate-language';
import { setComponentData } from '../../common/store/component-data/component-data-actions';
import { requestLoginPromisified } from '../../app/actions/request-login';
import { fetchForumData } from '../../app/actions/fetch-forum-data';
import { setSavedAction } from '../../common/store/saved-action/set-saved-action';
import { invokeSavedAction } from '../../app/services/invoke-saved-action';
import { uouBiEventsExecutor } from '../../app/bi-events/forums-uou-events-executor';
import {
  getMemoryCachedStore,
  saveStoreStateInMemoryCache,
} from '../../common/services/store-memory-cache';
import { getCurrentInstance } from '../../common/services/get-instance';
import { setCustomRoutes } from '../../common/store/custom-routes/custom-routes-actions';
import { userEventsClickProfileLink } from '../../app/actions/user-events';
import { SANTA_MEMBERS_APP_ID } from '../../app/constants/applications';
import { getStaticsVersionFromUrl } from '../../common/services/statics-version';
import { makeRelativeIfNeeded } from '../../app/controllers/make-relative-path-if-needed';
import { EXPERIMENT_DISABLE_API_RETRIES } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';

const isProduction = process.env.NODE_ENV === 'production';

const createMemoryStorageCacheKey = compId => `recent-posts-widget:${compId}:memory`;

export const createRecentPostsWidgetController = (
  { appParams, compId, config, setProps, wixCodeApi, platformAPIs, type },
  flowAPI,
) => {
  const memoryStorageCacheKey = createMemoryStorageCacheKey(compId);
  const isDebug = wixCodeApi.location.query.debug;
  const isSite = wixCodeApi.window.viewMode === 'Site';
  const isSSR = wixCodeApi.window.rendering.env === 'backend';
  const log = createLogger(isDebug, isProduction);

  log('createRecentPostsWidgetController', { appParams });

  const sentry = createSentryMonitor({
    flowAPI,
    isSSR,
    environment: ENVIRONMENT.RPW,
    appParams,
  });
  const fedopsLogger = flowAPI.fedops;

  const memoryCachedStore = getMemoryCachedStore(wixCodeApi, platformAPIs, memoryStorageCacheKey);
  let onSettingsChange;
  const pageReady = () => {
    log('createRecentPostsWidgetController.pageReady -> start');

    const language = validateLanguage(wixCodeApi.site.language);

    const store = createReduxStore({
      appParams,
      memoryCachedStore,
      wixCodeApi,
      compId,
      reducers,
      fedopsLogger,
      platformAPIs,
      language,
      isDebug,
      isSite,
      flowAPI,
      config,
      isSSR,
    });
    const actions = initializeActions({
      wixCodeApi,
      compId,
      store,
      fedopsLogger,
      sentry,
    });
    const actionsPromisified = initializePromisifiedActions({ wixCodeApi, compId, store, sentry });
    onSettingsChange = applySettingsChange(store);

    const promise = memoryCachedStore
      ? Promise.resolve()
      : initializeStoreData({ wixCodeApi, store, config, language, flowAPI });
    return promise
      .then(() => {
        log('createRecentPostsWidgetController.pageReady -> done');
        const state = store.getState();

        setProps({
          ...state,
          actions,
          actionsPromisified,
          cssBaseUrl: appParams.baseUrls.staticsBaseUrl,
          sentryRelease: getStaticsVersionFromUrl(appParams.baseUrls.staticsBaseUrl),
          isRTL: isRtlLanguage(language),
          isSSR,
        });

        if (wixCodeApi.window.viewMode === 'Editor') {
          listenToSettingsChange(store);
        }

        if (wixCodeApi.site.onInstanceChanged) {
          wixCodeApi.site.onInstanceChanged(({ instance }) => {
            store.dispatch(initialiseInstanceValues(instance));
            store.dispatch(setInstance(instance));
          }, appParams.appDefinitionId);
        }

        subscribeToChange(store, partialState => setProps({ ...partialState }));
        saveStoreStateInMemoryCache(
          platformAPIs,
          memoryStorageCacheKey,
          filterStateForCache(state),
        );
        updateLocalCacheOnStateChange({ platformAPIs, store, memoryStorageCacheKey });

        return state;
      })
      .catch(
        handleError({
          store,
          actions,
          actionsPromisified,
          setProps,
          appParams,
          sentry,
          isSSR,
        }),
      );
  };

  const controller = Promise.resolve({
    pageReady: () => {
      if (wixCodeApi.window.viewMode === 'Editor') {
        return handleProvisioning(
          appParams,
          fedopsLogger,
          wixCodeApi,
          setProps,
          pageReady,
          flowAPI,
        );
      }
      return pageReady();
    },
    updateConfig: ($w, config) => {
      console.log('[WIDGET] updateConfig', config);
      onSettingsChange(config);
    },
    exports: () => ({}),
  });

  controller.catch(console.error);

  return controller;
};

function updateLocalCacheOnStateChange({ store, platformAPIs, memoryStorageCacheKey }) {
  const debouncedUpdate = debounce(
    () =>
      // TODO: check in future if this performs, might need to do this selectively
      setTimeout(() =>
        saveStoreStateInMemoryCache(
          platformAPIs,
          memoryStorageCacheKey,
          filterStateForCache(store.getState()),
        ),
      ),
    200,
    { maxWait: 1000 },
  );
  store.subscribe(debouncedUpdate);
}

function createReduxStore({
  reducers,
  appParams,
  wixCodeApi,
  compId,
  fedopsLogger,
  memoryCachedStore,
  platformAPIs,
  language,
  isSite,
  flowAPI,
  config,
  isSSR,
}) {
  function createRequestWithBaseUrl(baseUrl) {
    const disableRetryOnError = flowAPI.experiments.enabled(EXPERIMENT_DISABLE_API_RETRIES);

    return createRequest({
      baseUrl,
      getInstance: () => getCurrentInstance(wixCodeApi),
      locale: language,
      petriOvr: wixCodeApi.location.query.petri_ovr,
      siteRevision: wixCodeApi.site.revision,
      httpClient: flowAPI.httpClient,
      isSSR,
      disableRetryOnError,
    });
  }
  const baseUrls = appParams.baseUrls;

  const baseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? makeRelativeIfNeeded(baseUrls.apiBaseUrlClient)
      : baseUrls.apiBaseUrlClient;

  const badgesApiBaseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? makeRelativeIfNeeded(baseUrls.apiBadgesBaseUrlClient)
      : baseUrls.apiBadgesBaseUrlClient;

  const wixCommentsExperimentBaseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? makeRelativeIfNeeded('/_serverless/comments-forum-integration')
      : '/_serverless/comments-forum-integration';

  const request = createRequestWithBaseUrl(baseUrl);
  const badgesRequest = createRequestWithBaseUrl(badgesApiBaseUrl);
  const wixCommentsExperimentApi = createRequestWithBaseUrl(wixCommentsExperimentBaseUrl);

  const getInstance = () => getCurrentInstance(wixCodeApi);

  return createStore(
    reducers,
    memoryCachedStore ? memoryCachedStore : undefined,
    composeWithReduxDevTools(wixCodeApi)(
      applyMiddleware(
        thunk.withExtraArgument({
          request,
          wixCodeApi,
          compId,
          appParams,
          fedopsLogger,
          badgesRequest,
          wixCommentsExperimentApi,
          httpClient: flowAPI.httpClient,
        }),
        createInteractionsMiddleware(fedopsLogger, {
          [INCREMENT_POST_LIKE_COUNT_SUCCESS]: LIKE_POST,
        }),
        initBiMiddleware({
          logger: flowAPI.bi,
          executor: uouBiEventsExecutor,
          instance: getInstance(),
          isSite,
          config,
          platformAPIs,
          wixCodeApi,
        }),
      ),
    ),
  );
}

function initializeActions({ wixCodeApi, store, fedopsLogger, sentry }) {
  return {
    interactionStarted: interaction => fedopsLogger.interactionStarted(interaction),
    interactionEnded: interaction => fedopsLogger.interactionEnded(interaction),

    requestLogin: mode => {
      const options = {};
      if (mode !== undefined) {
        options.mode = mode;
      }
      wixCodeApi.user.promptLogin(options).catch(noop);
    },
    navigateToProfile: ({ memberId, memberSlug }) => {
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then(api => {
        api.navigateToMember({ memberId, memberSlug }).catch(console.error);
      });
    },
    ...decorateActionsWithLogger(
      bindActionCreators(
        {
          navigateWithinForum,
          fetchRecentPosts,
          incrementPostLikeCount,
          showJoinCommunityPopup,
          setSavedAction,
          fetchBadges,
          userEventsClickProfileLink,
        },
        store.dispatch,
      ),
      sentry,
    ),
  };
}

function initializeStoreData({
  wixCodeApi,
  store,
  config: { publicData, style },
  language,
  flowAPI,
}) {
  const promises = [];

  promises.push(
    initUserDependentStoreData({ store, publicData, style, wixCodeApi, flowAPI }),
    isMaInstalled(wixCodeApi).then(isInstalled => {
      if (isInstalled) {
        store.dispatch(initializeMaNavigation());
      }
    }),
    store.dispatch(initializeLocation()),
    store.dispatch(setCommunitiesContext()),
    store.dispatch(fetchForumData()),
    store.dispatch(fetchTranslations(language)),
    store.dispatch(setBasicParams({ language })),
    store.dispatch(setComponentData(get(publicData, 'COMPONENT', {}))),
    store.dispatch(setCustomRoutes()),
  );

  wixCodeApi.user.onLogin(() => {
    setTimeout(() => {
      initUserDependentStoreData({ store, publicData, style, wixCodeApi }).then(() =>
        invokeSavedAction(store),
      );
    });
  });

  return Promise.all(promises);
}

function initUserDependentStoreData({ store, publicData, style, wixCodeApi, flowAPI }) {
  const user = wixCodeApi.user.currentUser;
  const { biToken } = parseInstance(getCurrentInstance(wixCodeApi));
  const isSiteSaved = biToken !== undefined;
  return Promise.all([
    user.loggedIn && isSiteSaved && wixCodeApi.user.currentUser.id
      ? store.dispatch(fetchUser({ siteMemberId: wixCodeApi.user.currentUser.id }))
      : Promise.resolve(),
    store.dispatch(fetchRecentPostsAction({ publicData, style })),
    store.dispatch(fetchAllCategories()),
    isSiteSaved ? store.dispatch(fetchBadges()) : Promise.resolve(),
    store.dispatch(fetchExperiments(flowAPI.experiments.experiments)),
    store.dispatch(initialiseInstanceValues(getCurrentInstance(wixCodeApi))),
  ]);
}

function fetchRecentPostsAction({ publicData, style }) {
  return fetchRecentPosts({
    categoryId: get(publicData.COMPONENT, WIDGET_CATEGORY_ID),
    orderBy: get(publicData.COMPONENT, WIDGET_POSTS_ORDER_BY, 'createdDate'),
    pageSize: get(style.styleParams.numbers, LIST_NUMBER_OF_POSTS, 3),
  });
}

function composeWithReduxDevTools() {
  return f => f;

  // const shouldEnable =
  //   wixCodeApi.location.query.debugStore && wixCodeApi.window.rendering.env !== 'backend';
  //
  // return shouldEnable
  //   ? composeWithDevTools({
  //       name: 'rpw',
  //       realtime: shouldEnable,
  //       port: 8000,
  //     })
  //   : f => f;
}

function createLogger(isDebug, isProduction) {
  return (...args) => {
    if (!isProduction || isDebug) {
      console.log(...args);
    }
  };
}

function filterStateForCache(state) {
  return {
    ...state,
    appLoaded: { hasReported: false },
  };
}

function initializePromisifiedActions({ store, sentry }) {
  return {
    ...decorateActionsWithLogger(
      bindActionCreators(
        {
          requestLoginPromisified,
        },
        store.dispatch,
      ),
      sentry,
    ),
  };
}
